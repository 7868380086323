import "./Footer.css"

function Footer() {
    return(
        <div className="footer">
            <h6>copyright © 2024 all rights reserved</h6>
        </div>
    )
}

export default Footer